import * as React from 'react'
import { IHeader } from './Header/Header'
import { IPage } from '../../Omnimerse/cms/Frontend/omnistudio-frontend-components/src/OmniPage/interfaces'
import css from './Layout.scss'
import { useEffect, useState } from 'react'

export interface ILayoutProps {
  // TODO: create proper interfaces
  header: IHeader[]
  footer: IPage[]
}

const findAndRemoveVideoElements = (
  obj: { [s: string]: unknown } | ArrayLike<unknown>,
  setVideoUrl: { (value: React.SetStateAction<string | null>): void; (arg0: any): void },
) => {
  for (const [key, value] of Object.entries(obj)) {
    if (value.children && Array.isArray(value.children)) {
      value.children = value.children.filter(element => {
        const hasVideoChild = element.children?.some(child => child.type === 'VIDEO')

        if (hasVideoChild) {
          element.children = element.children.filter(child => {
            if (child.type === 'VIDEO') {
              if (child.videoUrl) setVideoUrl(child.videoUrl) // Save video URL to state
              return false // Remove the video element
            }
            return true
          })
        }

        return true // Keep non-video elements
      })
    }
  }
}

/**
 * The core wrapper for all pages
 */
const Layout = (props: any) => {
  const miniheaders: string[] = ['/checkout']
  const homeHeader: string[] = ['/']
  const hasMargin: boolean = miniheaders.indexOf(props.url) === -1 ? true : false
  const noMarginTop: boolean = homeHeader.indexOf(props.url) !== -1 ? true : false
  const [renderVideo, setRenderVideo] = useState(false)
  const [videoUrl, setVideoUrl] = useState<string | null>(null)

  useEffect(() => {
    if (window.location.pathname === '/') {
      props?.children?.props?.data?.snapshot?.lookup &&
        findAndRemoveVideoElements(props.children.props.data.snapshot.lookup, setVideoUrl)
      setRenderVideo(true)
    }
  }, [])

  return (
    <React.Fragment>
      <div className={`${hasMargin ? (noMarginTop ? '' : css.layout) : css.checkoutHeader} cor-app margin-bottom`}>
        {renderVideo && videoUrl && (
          <video width="100%" height="100%" controls={false} autoPlay loop muted playsInline>
            <source src={videoUrl} type="video/mp4" />
          </video>
        )}
        {props.children}
      </div>
    </React.Fragment>
  )
}

export default Layout
