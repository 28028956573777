import * as React from 'react'
import { IFooterDataFormat } from '../../Footer'
import css from './MetaData.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faFacebookSquare,
  faInstagram,
  faTwitter,
  faPinterest,
  faHouzz,
  faTiktok,
} from '@fortawesome/free-brands-svg-icons'
import { MOR_PHONE_NUMBER } from '../../../../../../settings/variables'

export interface IProps {
  data: IFooterDataFormat
}

export default class MetaData extends React.Component<IProps, any> {
  renderSocialMediaIcons = () => {
    return (
      <>
        <div className={css.socialMedia}>
          <a
            href="https://www.facebook.com/MorFurniture/"
            aria-label="facebook"
            data-footer-nav="facebook"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faFacebookSquare} className={css.brandIcon} title="Facebook" focusable="false" />
          </a>
          <a
            href="https://www.instagram.com/morfurniture/?hl=en"
            aria-label="instagram"
            data-footer-nav="instagram"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faInstagram} className={css.brandIcon} title="Instagram" focusable="false" />
          </a>
          <a
            href="https://twitter.com/morfurniture"
            aria-label="twitter"
            data-footer-nav="twitter"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faTwitter} className={css.brandIcon} title="Twitter" focusable="false" />
          </a>
          <a
            href="https://www.pinterest.com/morfurniture/"
            aria-label="pinterest"
            data-footer-nav="pinterest"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faPinterest} className={css.brandIcon} title="Pinterest" focusable="false" />
          </a>
          <a
            href="https://www.houzz.com/pro/morfurniture/mor-furniture-for-less"
            aria-label="houzz"
            data-footer-nav="houzz"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faHouzz} className={css.brandIcon} title="Houzz" focusable="false" />
          </a>
          <a
            href="https://www.tiktok.com/@morfurniture"
            aria-label="tiktok"
            data-footer-nav="tiktok"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faTiktok} className={css.brandIcon} title="Tiktok" focusable="false" />
          </a>
        </div>
      </>
    )
  }
  renderEmail = () => {
    const { email } = this.props.data
    if (!email) {
      return <div />
    }
    return (
      <div>
        <a href={`mailto:${this.props.data.email}`} data-footer-nav="Email Link">
          {this.props.data.email}
        </a>
      </div>
    )
  }
  renderPhoneNumber = () => {
    return (
      <div>
        <a href={`tel:+1${MOR_PHONE_NUMBER}`} data-footer-nav="Phone">
          Call or text us at {MOR_PHONE_NUMBER}.
        </a>
      </div>
    )
  }

  renderStoreHours = () => {
    return (
      <div>
        <p style={{ marginBottom: '15px' }}>
          <a href="/storelocator" data-footer-nav="Find a Store">
            Find a Store
          </a>
        </p>
      </div>
    )
  }

  public render() {
    return (
      <div className={css.metaFooter}>
        <div className={css.mobileIcons}>{this.renderSocialMediaIcons()}</div>
        <p className={css.title}>Contact Us</p>
        {this.renderPhoneNumber()}
        {this.renderEmail()}
        {this.renderStoreHours()}
        <div className={css.desktopIcons}> {this.renderSocialMediaIcons()}</div>
      </div>
    )
  }
}
